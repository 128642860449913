import { Container, Row, Col } from "react-bootstrap";
import { ChangelogItem } from "../components/changelog/ChangelogItem";

const styles = {
  title: {
    color: "rgb(70, 130, 180)",
    marginBottom: "16px",
    marginTop: "32px",
  },
};

function Changelog() {
  return (
    <Container>
      <Row>
        <h3 style={styles.title}>Changelog</h3>
        <Col>
          <ChangelogItem
            title="08/28/2024"
            changes={[
              "Added remaining races https://aardstats.com/race-modifiers",
              "Added all race affects https://aardstats.com/race-modifiers",
            ]}
          />
          <ChangelogItem
            title="06/24/2024"
            changes={[
              "Updated Races to match MUD changes https://aardstats.com/race-modifiers",
            ]}
          />
          <ChangelogItem
            title="05/25/2024"
            changes={[
              "Add Race Resists and Stats to https://aardstats.com/race-modifiers",
            ]}
          />
          <ChangelogItem
            title="05/08/2024"
            changes={[
              "Race Modifiers page added https://aardstats.com/race-modifiers",
            ]}
          />
          <ChangelogItem
            title="05/05/2024"
            changes={[
              "Added screen /subclasses",
              "Added screen /races",
              "Added stat cards on Dashboard",
              "Removed Wanted on Dashboard",
              "Split OPK and HC activity tabs",
              "Added dropdowns in Header menu for more items",
            ]}
          />
          <ChangelogItem
            title="04/26/2024"
            changes={[
              "Started tracking 8 new data points from gamestat 5",
              "Added Online, SHs, IMMs, Helpers on Dashboard, replacing PK data for current week",
              "Replaced Current Week on Dashboard with Hour Double Predictor",
            ]}
          />
          <ChangelogItem
            title="03/24/2024"
            changes={[
              "Started tracking other activities",
              "Fixed reported bugs - UI and queries",
              "Added current player count on Dashboard",
            ]}
          />
          <ChangelogItem
            title="11/24/23"
            changes={[
              "Add streaks to Player page",
              "Add recoded ranks to Player page",
              "Add charts to Player page",
              "Add shortcut links to player names on front page",
              "Style adjustments",
            ]}
          />
          <ChangelogItem
            title="11/22/23"
            changes={["Add streaks to Dashboard's Latest 10"]}
          />
          <ChangelogItem
            title="11/20/23"
            changes={["Add calendar for epics -- https://aardstats.com/epics"]}
          />
          <ChangelogItem
            title="09/14/23"
            changes={[
              "[Epics] Added support for more time formats",
              "[Epics] Deleted, removed, cancelled epics are removed more frequently",
              "[Epics] Enhanced logging around epic notes to detect unhandled formats earlier",
            ]}
          />
          <ChangelogItem
            title="07/10/23"
            changes={[
              "Resolved some responsiveness issues for epics",
              "Muted epics that are likely over",
            ]}
          />
          <ChangelogItem
            title="07/06/23"
            changes={[
              "Dashboard changes for various time formats",
              "Added additional data to Last 10 table",
              "Theming changes for Last 10 table",
              "Label today's epics in red",
            ]}
          />
          <ChangelogItem
            title="07/01/23"
            changes={["Added note ID and original note subject to epic cards"]}
          />
          <ChangelogItem
            title="06/28/23"
            changes={[
              "Changed time format for epics to match Google styleguide",
              "Added support for epics from other leaders that tag along after other epics",
              "Cleaned up discord messages",
            ]}
          />
          <ChangelogItem
            title="06/25/23"
            changes={["Added Epics under https://aardstats.com/"]}
          />
          <ChangelogItem
            title="06/14/23"
            changes={[
              "Added Top 10 Most Killed and Most Killed section by under https://aardstats.com/player",
            ]}
          />
          <ChangelogItem
            title="06/11/23"
            changes={[
              "Added Players dedicated page with lookups",
              "Added links from Dashboard Recent PKs to Feed",
              "Added links from Feed to Player pages",
            ]}
          />
          <ChangelogItem
            title="06/06/23"
            changes={[
              "Changes to Discord webhooks",
              "Added recent activity: OPK/HC toggles",
              "Added recent activity: SETWANTED",
              "BE changes for consistency",
            ]}
          />
          <ChangelogItem
            title="06/06/23"
            changes={[
              "Started tracking HC flag toggles",
              "Started tracking SETWANTEDs",
              "Re-organized stats displayed on Dashboard",
              "Behind the scenes changes for BE refactor",
              "Dashboard layout changes for UX",
            ]}
          />
          <ChangelogItem
            title="06/01/23"
            changes={[
              "Started tracking PKer's level, subclass, race, tier",
              "Started tracking PKee's level, subclass, race, tier",
              "Wanted PKs are now marked WANTED (instead of PK)",
            ]}
          />
          <ChangelogItem
            title="05/11/23"
            changes={[
              "Refactored header to be responsive",
              "Other UX/UI changes",
            ]}
          />
          <ChangelogItem
            title="05/08/23"
            changes={[
              "Changed `/leaderboards` filters to use URL params for hotlinking",
              "Fixed a bug with charts on dashboard",
              "Removed `Last week` standalone page",
            ]}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Changelog;
