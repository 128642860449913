export const getRaceAffects = (race: string) => {
  if (race) {
    const foundRace = raceAffects.find((e) => e.race === race);
    const affects = foundRace?.affects || [];

    return affects;
  }

  return [];
};

const raceAffects = [
  {
    race: "Centaur",
    affects: [
      "Kick damage is increased.",
      "Kick has no lag (but a small recovery).",
      "Kick has a chance to stun.",
      "Deals 10% more damage in forest, hills, or field type rooms.",
    ],
  },
  {
    race: "Dark elf",
    affects: [
      "Permanent Night Vision.",
      "Hide and Sneak Skills.",
      "Permanent Detect Hidden and Detect Magic.",
      "10% more damage fighting in naturally dark rooms.",
      "50% more regeneration in naturally dark rooms.",
    ],
  },
  {
    race: "Diva",
    affects: [
      "10% Resistance in nature sectors.",
      "Permanent Detect Good, Detect Evil.",
      "Access to Tame Skill.",
    ],
  },
  {
    race: "Dwarf",
    affects: [
      "Bonus stats when tempering.",
      "Reduced penalty from Berserk.",
      "Permanent Night Vision.",
    ],
  },
  {
    race: "Eldar",
    affects: [
      "Permanent access to Lore command (shows full identify).",
      "Most spells cost 25% less mana.",
      "Meditation passive skill.",
    ],
  },
  {
    race: "Elf",
    affects: ["Permanent Night Vision.", "Hide and Sneak Skills."],
  },
  {
    race: "Gargoyle",
    affects: ["10% damage in air sectors.", "Permanent fly."],
  },
  {
    race: "Giant",
    affects: [
      "Hits have a small chance to stun.",
      "Permanent Blockexit Skill.",
      "Bash Skill.",
      "Skill damage does not include backstab",
    ],
  },
  {
    race: "Halfling",
    affects: [
      "Permanent Detect Hidden.",
      "Affinity for Treasure - loots better 'bonusloot' ranks.",
    ],
  },
  {
    race: "Naga",
    affects: [
      "Can Envenom Weapons.",
      "Additional Envenom damage on weapons (as Venomist only).",
      "10% more damage against poisoned opponents.",
    ],
  },
  {
    race: "Ogre",
    affects: ["Skill damage does not include backstab"],
  },
  {
    race: "Sprite",
    affects: [
      "Permanent Haste.",
      "Permanent Hide, Flying and Invisibility.",
      "Can use Second Attack as any class.",
      "Can consume corpses for Mana.",
      "All actions are (on average) 10% faster.",
    ],
  },
  {
    race: "Tigran",
    affects: ["Automatic skill to claw and bite enemies.", "Permanent Sneak."],
  },
  {
    race: "Triton",
    affects: [
      "Permanent Underwater Breathing.",
      "Inflicts 10% more damage in water based rooms.",
      "Regenerates more quickly in water based rooms.",
      "Does not require a boat or fly to travel over water.",
      "Regenerates much more slowly in desert areas.",
    ],
  },
  {
    race: "Troll",
    affects: [
      "Continuous Regen.",
      "Regen at tick as if sleeping if not fighting.",
      "Bash skill does more damage.",
      "Skill damage does not include backstab",
    ],
  },
  {
    race: "Vampire",
    affects: [
      "Can consume corpses for HP - receive more at night.",
      "Inflict 10% more damage during the night and 5% less during the day. Evening is neutral.",
      "Regenerates faster at night, slower during the day.",
      "Permanent Detect Invis, Night Vision, Flying and Intimidate.",
      "Vampires take more damage when caught outside during the daytime.",
    ],
  },
  {
    race: "Wolfen",
    affects: [
      "Permanent Detect Invis, Hide, Sneak, Night Vision.",
      "Permanent access to hunt skill.",
      "Increased experience in groups based on number of wolves in group.",
      "Damage partly based on number of moons in the sky. See 'help raceperks'.",
    ],
  },
  {
    race: "Wraith",
    affects: [
      "10% more damage when using Shadow.",
      "Permanent Pass Door and Flying.",
      "Movement doesn't cost moves.",
      "Permanent sneak (shadow form).",
    ],
  },
  {
    race: "Human",
    affects: ["1 extra quest point per quest.", "5% more damage in a city."],
  },
];
